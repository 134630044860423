.folder {
    border:'none'
}



.file {
    border:'none';
    cursor: 'pointer'
}

.file:hover{
    opacity: .5;
    transition-duration: .5s;
}